import { useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

function Staf(props) {
    
    return (
        <div id="Staf">
            <Header userInfo={props.userInfo} logout={props.logout}/>
            <main>
                <ul className="staf_wrap">
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">CEO</p>
                            <h3>고호준</h3>
                            <p className="hashtag">#ENTP #건축가<br/>#감각적인디자이너 #존레논 <br/>#뒷태미녀 #맛집잘알</p>
                        </div>
                    </li>
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">CTO</p>
                            <h3>윤주원</h3>
                            <p className="hashtag">#INFP #햎피뤂피<br/>#프롭메이트의어머니 #꺄르르<br/>#행복한햄스터 #분위기메이커</p>
                        </div>
                    </li>
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">MANAGER</p>
                            <h3>김동욱</h3>
                            <p className="hashtag">#ISTP #백바담의아버지<br/>#프롭메이트의클라이머<br/>#전완근29cm #사실은설계천재</p>
                        </div>
                    </li>
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">ENGINEER</p>
                            <h3>김종인</h3>
                            <p className="hashtag">#ISTJ #교회오빠<br/>
                            #클라이밍천재 #정리아티스트<br/>#디자인개발둘다가능</p>
                        </div>
                    </li>
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">ENGINEER</p>
                            <h3>이형우</h3>
                            <p className="hashtag">#ISFP #코딩아티스트<br/>#자칭이시대최고의천재개발자<br/>#프롭메이트의배드민턴선수</p>
                        </div>
                    </li>
                    <li>
                        <h2></h2>
                        <div className="txt_box">
                            <p className="position">DESIGNER</p>
                            <h3>한유빈</h3>
                            <p className="hashtag">#INTP #손빠른디자이너<br/>#알잘딱깔센정석 #막내이긴함<br/> #뭐든지빨리배움</p>
                        </div>
                    </li>
                    <li className="easter_egg1 easter_egg"></li>
                    <li className="easter_egg2 easter_egg"></li>
                    <li className="easter_egg3 easter_egg"></li>
                    <li className="easter_egg4 easter_egg"></li>
                    <li className="easter_egg5 easter_egg"></li>
                    <li className="easter_egg6 easter_egg"></li>
                    <li className="egg1 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat1.png"}></img>
                    </li>
                    <li className="egg2 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat2.png"}></img>
                    </li>
                    <li className="egg3 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat3.png"}></img>
                    </li>
                    <li className="egg4 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat4.png"}></img>
                    </li>
                    <li className="egg5 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat5.png"}></img>
                    </li>
                    <li className="egg6 egg">
                        <img src={process.env.PUBLIC_URL+"images/staf/cat6.png"}></img>
                    </li>
                </ul>
            </main>
            <div className="footer_bg">
                <footer>
                <ul className="footer_info">
                    <li><address>주소 | 서울시 광진구 광나루로 478, 407호</address></li>
                    <li>대표 | 고호준</li>
                    <li>개인정보 관리 책임자 | 윤주원</li>
                    <li>사업자등록번호 | 469-81-03262</li>
                </ul>
                <ul className="footer_link">
                    <li><Link to={'/Company'}>회사소개</Link></li>
                    <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
                    <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
                    <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
                </ul>
                <p>Copyright 2023 propmate Inc. all rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}

export default Staf;